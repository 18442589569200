<div class="dialog dialog-generic">
  <div class="dialog-generic__flex">
    @if (state === "success") {
      <i class="dialog-generic__success">
        <svg-icon src="assets/images/icons/tick.svg"></svg-icon>
      </i>
    }
    @if (state === "error") {
      <i class="dialog-generic__error">
        <svg-icon src="assets/images/icons/x.svg"></svg-icon>
      </i>
    }
    <div class="dialog-generic__body">
      @if (title) {
        <h3 class="dialog-generic__title">{{ title }}</h3>
      }
      @if (message) {
        <div class="dialog-generic__text">{{ message }}</div>
      }
      @if (code) {
        <pre>{{ code }}</pre>
      }
      <button class="button button--secondary" mat-dialog-close>{{ "Global.Action.Close" | translate }}</button>
    </div>
  </div>
</div>
