import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { ChartFilterType } from "../helpers/types";
import { SubscriptionUpdateMessage } from "../components/selfservice/selfservice-loader/selfservice-loader";

export interface SubscriptionUpdate<S> {
  subscription: S;
}

@Injectable()
export class MessageService {
  public enableFilterSource = new Subject<any>();
  public enableMalfunctionFilterSource = new Subject<any>();
  public disableFilterSource = new Subject<any>();
  public setMalfunctionFilterDateSource = new Subject<any>();
  public malfunctionLoadMoreSource = new Subject<any>();
  public chartFilterSource = new Subject<ChartFilterType>();
  public subscriptionUpdateSource = new Subject<SubscriptionUpdate<any>>();

  enableFilter$ = this.enableFilterSource.asObservable();
  enableMalfunctionFilter$ = this.enableMalfunctionFilterSource.asObservable();
  disableFilter$ = this.disableFilterSource.asObservable();
  malfunctionFilterDate$ = this.setMalfunctionFilterDateSource.asObservable();
  malfunctionLoadMore$ = this.malfunctionLoadMoreSource.asObservable();
  chartFilter$ = this.chartFilterSource.asObservable();
  subscriptionUpdate$ = this.subscriptionUpdateSource.asObservable();

  constructor() {}

  enableFilter(data) {
    this.enableFilterSource.next(data);
  }
  disableFilter(data) {
    this.disableFilterSource.next(data);
  }
  setChartFilter(data: ChartFilterType) {
    this.chartFilterSource.next(data);
  }

  enableMalfunctionFilter(data) {
    this.enableMalfunctionFilterSource.next(data);
  }

  setMalfunctionFilterDate(data) {
    this.setMalfunctionFilterDateSource.next(data);
  }

  setMalfunctionLoadMore(data) {
    this.malfunctionLoadMoreSource.next(data);
  }

  setSubscription<S>(data: SubscriptionUpdateMessage<S>) {
    this.subscriptionUpdateSource.next({ subscription: data.subscription });
  }
}
