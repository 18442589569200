import { Component, computed, inject, signal } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Customer } from "../../models/customer";
import { AuthService } from "../../../services/AuthService";
import { CommonModule } from "@angular/common";
import { AngularSvgIconModule } from "angular-svg-icon";
import { FormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";

type CustomerWithSearchKey = Customer & { searchKey: string };

@Component({
  selector: "dialog",
  templateUrl: "dialog.html",
  styleUrls: ["dialog.scss"],
  imports: [CommonModule, AngularSvgIconModule, FormsModule, TranslateModule],
})
export class ChooseOrganisationDialogComponent {
  protected activeTab = 0;
  protected activeTabDialog = 0;
  private organisations: CustomerWithSearchKey[] = [];
  protected searchValue = signal("");
  protected filteredOrganisations = computed(() => {
    const needle = this.searchValue().toLowerCase();
    return this.organisations.filter((org) => org.searchKey.includes(needle));
  });

  private readonly auth = inject(AuthService);
  private readonly dialogRef = inject(MatDialogRef<ChooseOrganisationDialogComponent>);

  constructor() {
    this.auth.userLoaded.subscribe(() => {
      this.organisations = this.auth.state.organisations.map((org) => ({
        ...org,
        searchKey: `${org.name.toLowerCase()} ${org.abbreviation.toLowerCase()}`,
      }));
    });
  }

  switchTab(tab: number) {
    this.activeTab = tab;
  }

  switchTabDialog(tabdialog: number) {
    this.activeTabDialog = tabdialog;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  switchCustomer(guid: string): void {
    this.auth.switchViewingCustomer(guid, "/");
  }
}
