<header class="header" [ngClass]="{ sticky: isSticky }">
  <section class="topbar">
    <div class="container">
      <div class="topbar__flex">
        <a [routerLink]="['/']" class="logo"></a>
        <div class="navigation__items">
          <a
            [routerLink]="['/']"
            class="navigation__link"
            routerLinkActive="navigation__link--active"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <span class="navigation__link-inner">{{ "Navigation.Subscriptions" | translate }}</span>
          </a>
          <a
            [routerLink]="['/storing-en-onderhoud']"
            class="navigation__link"
            routerLinkActive="navigation__link--active"
          >
            <span class="navigation__link-inner">{{ "Navigation.MalfunctionAndMaintenance" | translate }}</span>
          </a>
          <a [routerLink]="['/support']" class="navigation__link" routerLinkActive="navigation__link--active">
            <span class="navigation__link-inner">{{ "Navigation.Support" | translate }}</span>
          </a>
          <a class="navigation__link">&nbsp;|&nbsp;</a>
          <a href="{{ newsUrl }}" class="navigation__link">
            <span class="navigation__link-inner">{{ "Navigation.News" | translate }}</span>
          </a>
        </div>

        <div class="topbar__items">
          <div class="topbar__item__wrapper">
            <customer-display (openSwitchDialog)="openChooserDialog()"></customer-display>
          </div>
          <profile-menu (openRolesDialog)="openRolesDialog()" (logout)="logOut()"></profile-menu>
        </div>
      </div>
    </div>
  </section>
  <div class="navigation" [class.fixed]="navIsFixed" *ngIf="router.url !== '/error'">
    <div class="container">
      <div class="navigation__flex" [class.terminated]="terminated">
        <div class="navigation__text--wrapper">
          <span *ngIf="productType" class="navigation__icon navigation__icon__{{ productType?.toLowerCase() }}">
            <svg-icon
              src="assets/images/icons/{{ productType?.toLowerCase() }}.svg"
              class="service-tile__icon"
            ></svg-icon>
            <span
              *ngIf="firewallIconVisible && productType.toLowerCase() !== 'fw'"
              class="navigation-icon__overlap-square"
            >
              <svg-icon src="assets/images/icons/fw-overlap.svg" class="service-tile__overlap-icon"></svg-icon>
            </span>
          </span>
          <span *ngIf="IPPrefixIconVisible" class="navigation__icon navigation__icon__ip_prefix">
            <svg-icon src="assets/images/icons/ip_prefix.svg" class="service-tile__icon"></svg-icon>
          </span>
          <span *ngIf="CertIconVisible" class="navigation__icon navigation__icon__cert">
            <svg-icon src="assets/images/icons/cert.svg" class="service-tile__icon"></svg-icon>
          </span>
          <span *ngIf="DomainsIconVisible" class="navigation__icon navigation__icon__domains">
            <svg-icon src="assets/images/icons/domains.svg" class="service-tile__icon"></svg-icon>
          </span>
          <span class="navigation__text">
            <span *ngIf="routeTitle && !isChildRoute" class="navigation__text--top">{{ routeTitle }}</span>
            <button *ngIf="isChildRoute === true" (click)="goBack()" class="navigation__text--top navigation__back">
              <svg-icon src="assets/images/icons/icon-arrow-left.svg" class="back-button"> </svg-icon>
              {{ "Global.Action.Back" | translate }}
            </button>

            <span class="navigation__text--main" *ngIf="isDetailPage === false">
              {{ forceTitle ? forceTitle : mainNavText }}</span
            >

            <span class="navigation__text--main" *ngIf="isDetailPage === true && detailData !== undefined">
              <svg-icon
                *ngIf="productType !== 'Wireless' && !terminated"
                src="assets/images/icons/status-{{ detailData.status | status_to_icon }}.svg"
                class="table__status status-icon"
              ></svg-icon>

              <svg-icon
                *ngIf="terminated"
                src="assets/images/icons/status-terminated.svg"
                class="table__status status-icon"
              ></svg-icon>
              <span class="navigation__truncate">{{ detailData.title }}</span>
            </span>
          </span>
        </div>
        <div class="navigation__right">
          <!-- this will contain the components for self-service and the overview button -->
          <selfservice-status *ngIf="userCanStartSelfservice()"></selfservice-status>
          <span class="navigation__divider"></span>
        </div>
      </div>
    </div>
    <div *ngIf="subNavItems.length > 0" class="navigation__sub">
      <div class="container">
        <div class="navigation__sub-flex">
          <a *ngFor="let item of subNavItems" href="" [routerLink]="item.link" routerLinkActive="active">
            {{ item.title | translate }}
          </a>
        </div>
      </div>
    </div>
  </div>
</header>
<!-- temporary disabled maintenance banner in devMode -->
<div *ngIf="auth?.state.configuration.maintenanceBanner?.enabled && !devMode" class="maintenance-banner__wrapper">
  <div class="container">
    <div class="maintenance-banner">
      <div class="maintenance-banner__content">
        <div class="maintenance-banner__title">{{ auth.state.configuration.maintenanceBanner?.title }}</div>
        <div class="maintenance-banner__text">{{ auth.state.configuration.maintenanceBanner?.contents }}</div>
      </div>
    </div>
  </div>
</div>
