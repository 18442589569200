import { CommonModule } from "@angular/common";
import { Component, input } from "@angular/core";
import { MatTooltip } from "@angular/material/tooltip";

@Component({
  selector: "selfservice-toggle",
  templateUrl: "selfservice-toggle.html",
  styleUrls: ["selfservice-toggle.scss"],
  imports: [CommonModule, MatTooltip],
})
export class SelfserviceToggleComponent {
  protected active = input.required<boolean>();
  protected tooltipText = input("");
}
