<div class="topbar__item__wrapper" (clickOutside)="closeMenu()">
  <div
    class="topbar__item topbar__item--light topbar__item--dropdown"
    [ngClass]="{ clicked: isExpanded() }"
    (click)="toggleExpandedState()"
  >
    <svg-icon class="topbar__icon" src="assets/images/icons/user.svg"></svg-icon>
  </div>
  @if (isExpanded()) {
    <div class="topbar__options">
      <div class="topbar__profile">
        <div class="topbar__avatar" style="background-image: url(/assets/images/avatar.jpg)"></div>
        <div class="topbar__name">{{ userDisplayName() }}</div>
        <div class="topbar__organisation">{{ customerDisplayName() }}</div>
        <div class="topbar__version">
          <span class="clipboard__rule"
            >{{ nwdReleaseVersion }}
            <span class="clipboard__link" ngxClipboard [cbContent]="nwdReleaseVersion">
              <div class="clipboard__icon">
                <svg-icon src="assets/images/icons/copy.svg"></svg-icon>
              </div>
            </span>
          </span>
        </div>
      </div>
      <a [routerLink]="['/notification-settings']" class="topbar__button topbar__button&#45;&#45;switch">
        <span>{{ "Navigation.NotificationSettings" | translate }}</span>
      </a>
      @if (hasMultipleCustomers()) {
        <span (click)="openChooserDialog()" class="topbar__button topbar__button--switch" style="cursor: pointer">
          <svg-icon src="assets/images/icons/refresh.svg" class="topbar__button__icon"></svg-icon>
          <span>{{ "Navigation.Choose.Organisation" | translate }}</span>
        </span>
      }
      <span (click)="emitOpenRolesDialog()" class="topbar__button topbar__button--switch" style="cursor: pointer">
        <span>{{ "Navigation.Roles" | translate }}</span>
      </span>
      <div class="topbar__button topbar__button--switch">
        <span class="topbar__item">
          <span class="topbar__language" *ngFor="let lang of languages()">
            <span (click)="switchLanguage(lang)" [class.topbar__language--active]="currentLanguage() === lang">
              {{ lang }}
            </span>
          </span>
        </span>
      </div>
      <div (click)="sendDiagnostics()" class="topbar__button topbar__button--switch">
        <span class="topbar__item">{{ "Global.GenerateDiagnostics" | translate }}</span>
      </div>
      @if (!isProduction()) {
        <a (click)="emitLogout()" class="topbar__button">
          <svg-icon src="assets/images/icons/logout.svg" class="topbar__button__icon"></svg-icon>
          <span>{{ "Navigation.Logout" | translate }}</span>
        </a>
      }
    </div>
  }
</div>
