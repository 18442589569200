import { Component, computed, inject, Signal } from "@angular/core";
import { AuthService } from "../../../services/AuthService";
import { TranslateModule } from "@ngx-translate/core";
import { CommonModule } from "@angular/common";
import { AngularSvgIconModule } from "angular-svg-icon";
import { MatDialog } from "@angular/material/dialog";
import { DialogAuthComponent } from "../../dialogs/dialog-auth/dialog-auth";
import { FormatDurationPipe } from "../../../helpers/datehelper/duration";

@Component({
  selector: "selfservice-status",
  templateUrl: "./selfservice-status.html",
  styleUrls: ["./selfservice-status.scss"],
  imports: [CommonModule, TranslateModule, AngularSvgIconModule, FormatDurationPipe],
})
export class SelfserviceStatusComponent {
  protected auth = inject(AuthService);
  private dialog = inject(MatDialog);

  selfserviceStarted = computed(() => this.auth && this.auth.selfServiceStarted());

  startSelfService() {
    this.dialog.open(DialogAuthComponent, { data: { state: false } });
  }
}
