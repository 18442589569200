import { Component, inject, Inject, output, signal } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogClose } from "@angular/material/dialog";
import { TranslateModule } from "@ngx-translate/core";
import { AngularSvgIconModule } from "angular-svg-icon";

@Component({
  selector: "dialog-message",
  templateUrl: "dialog-message.html",
  styleUrls: ["dialog-message.scss"],
  imports: [AngularSvgIconModule, MatDialogClose, TranslateModule],
})
export class DialogMessageComponent {
  public dialogRef = inject(MatDialogRef<DialogMessageComponent>);

  public emitter = output<boolean>();

  protected title = signal("");
  protected message = signal("");
  protected isConfirm = signal(false);

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.title.set(data.title);
    this.message.set(data.message);
    this.isConfirm.set(data.isConfirm ?? false);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  emitPositive() {
    this.emitter.emit(true);
    this.closeDialog();
  }

  emitNegative() {
    this.emitter.emit(false);
    this.closeDialog();
  }
}
