import { Component, Input, OnChanges, output, SimpleChanges } from "@angular/core";
import { PageInfo } from "../../../gql/generated";
import { TranslateModule } from "@ngx-translate/core";
import { CommonModule } from "@angular/common";
import { AngularSvgIconModule } from "angular-svg-icon";

@Component({
  selector: "pagination",
  templateUrl: "pagination.html",
  styleUrls: ["pagination.scss"],
  imports: [CommonModule, TranslateModule, AngularSvgIconModule],
})
export class PaginationComponent implements OnChanges {
  @Input() pageInfo: PageInfo;
  @Input() itemsPerPage = 20;

  paginationChanged = output<number>();

  public firstItem: number;
  public lastItem: number;
  public hasNextPage = false;

  nextPage() {
    if (this.pageInfo.hasNextPage) {
      const endCursor = (this.pageInfo?.endCursor ?? 0) + 1;
      this.paginationChanged.emit(endCursor);
    }
  }

  prevPage() {
    if (this.pageInfo.hasPreviousPage) {
      const startCursor = (this.pageInfo?.startCursor ?? 0) - this.itemsPerPage;
      this.paginationChanged.emit(Math.max(startCursor, 0));
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["pageInfo"]) {
      this.firstItem = Number(this.pageInfo?.startCursor ?? -1) + 1;
      this.lastItem = Number(this.pageInfo?.endCursor ?? -1) + 1;

      // pageInfo.hasNextPage and pageInfo.totalItems seem to be buggy in backend,
      // work around like this.
      this.hasNextPage = (this.pageInfo?.endCursor ?? -2) + 2 === (this.pageInfo?.totalItems ?? 0);
    }
  }
}
