<div class="pagination-wrapper">
  <div>
    {{ "Pagination.Result" | translate }} {{ firstItem }} {{ "Pagination.to" | translate }} {{ lastItem }}
    {{ "Pagination.of" | translate }} {{ pageInfo?.totalItems - 1 }}
  </div>
  <nav class="pagination">
    <button
      (click)="prevPage()"
      [disabled]="!pageInfo?.hasPreviousPage"
      [ngClass]="{ disabled: !pageInfo?.hasPreviousPage }"
    >
      <svg-icon src="assets/images/icons/chevron-down.svg"></svg-icon>
    </button>
    <button (click)="nextPage()" [disabled]="hasNextPage" [ngClass]="{ disabled: hasNextPage }">
      <svg-icon src="assets/images/icons/chevron-down.svg"></svg-icon>
    </button>
  </nav>
</div>
