import { CommonModule } from "@angular/common";
import { Component, inject, output, signal } from "@angular/core";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { AngularSvgIconModule } from "angular-svg-icon";
import { NWD_VERSION } from "../../../version";
import { AuthService } from "../../../services/AuthService";
import { RouterLink } from "@angular/router";
import { ClipboardModule } from "ngx-clipboard";
import { MatDialog } from "@angular/material/dialog";
import { ChooseOrganisationDialogComponent } from "../../dialogs/choose-organisation/dialog";
import { NgClickOutsideDirective } from "ng-click-outside2";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { ENV } from "../../../app/app.runtime";
import { ApiHelper } from "../../../helpers/apihelper";
import { DialogGenericComponent } from "../../dialogs/dialog-generic/dialog-generic";

@Component({
  selector: "profile-menu",
  imports: [TranslateModule, CommonModule, AngularSvgIconModule, ClipboardModule, RouterLink, NgClickOutsideDirective],
  templateUrl: "./profile-menu.component.html",
  styleUrls: ["./profile-menu.component.scss", "../navigation/navigation.scss"],
})
export class ProfileMenuComponent {
  private auth = inject(AuthService);
  private translate = inject(TranslateService);
  private dialog = inject(MatDialog);
  private oidc = inject(OidcSecurityService);
  private api = inject(ApiHelper);

  public openRolesDialog = output();
  public logout = output();

  protected isExpanded = signal(false);
  protected customerDisplayName = signal("");
  protected userDisplayName = signal("");
  protected hasMultipleCustomers = signal(false);
  protected languages = signal<string[]>([]);
  protected currentLanguage = signal<string>(this.translate.currentLang);
  protected isProduction = signal(ENV.NWD_DASHBOARD_URL.includes("api.automation.surf.net"));
  protected nwdReleaseVersion = NWD_VERSION;

  constructor() {
    this.auth.userLoaded.subscribe(() => {
      this.customerDisplayName.set(this.auth.state.viewingCustomer.name);
      this.userDisplayName.set(this.auth.state.currentUser.displayName);
      this.hasMultipleCustomers.set(this.auth.state.organisations.length > 1);
    });
    this.languages.set(this.translate.getLangs());
    this.currentLanguage.set(this.translate.currentLang);
    this.translate.onLangChange.subscribe((event) => {
      this.currentLanguage.set(event.lang);
    });
  }

  protected async sendDiagnostics() {
    this.api
      .fileDiagnosticReport()
      .then((response) => {
        this.dialog.open(DialogGenericComponent, {
          data: {
            title: this.translate.instant("ProfileMenu.Diagnostics.Title"),
            message: this.translate.instant("ProfileMenu.Diagnostics.Message"),
            state: "success",
          },
        });
        this.closeMenu();
      })
      .catch((error) => {
        console.log(error);
        this.dialog.open(DialogGenericComponent, {
          data: {
            title: this.translate.instant("ProfileMenu.Diagnostics.Title"),
            message: this.translate.instant("ProfileMenu.Diagnostics.Error"),
            state: "error",
            code: `${error.status} ${error.statusText}`,
          },
        });
      });
  }

  protected toggleExpandedState(): void {
    this.isExpanded.update((previous) => !previous);
  }

  protected closeMenu(): void {
    this.isExpanded.update(() => false);
  }

  protected openChooserDialog() {
    this.dialog.open(ChooseOrganisationDialogComponent, {});
  }

  protected emitOpenRolesDialog() {
    this.openRolesDialog.emit();
  }

  protected emitLogout() {
    this.logout.emit();
  }

  protected switchLanguage(lang: string): void {
    this.translate.use(lang);
    localStorage.setItem("lang", lang);
  }
}
