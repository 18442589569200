<div class="loader" [ngClass]="{ loader__detailed: detailed(), loader__small: paddingSize() === 'small' }">
  <div [ngClass]="{ loader__center: !detailed() }" [ngClass]="{ loader__left: detailed() }">
    <div class="loader__circle" [ngClass]="{ 'loader__circle--small': small() }"></div>
    <div class="loader__text" [ngClass]="{ loader__text__detailed: detailed() }">
      @if (!iconOnly()) {
        <span>{{ "Global.Loading" | translate }}</span>
      }
      @if (detailed()) {
        <span>{{ text() }}</span>
      }
    </div>
  </div>
</div>
