/**
 * Auth service
 *
 */

import { Output, EventEmitter, isDevMode, inject, Injectable, signal, computed } from "@angular/core";
import { ApiHelper } from "../helpers/apihelper";
import { CurrentUser } from "../components/models/currentuser";
import {
  AccessRules,
  RoleCheckResult,
  rolesWithEditAccess,
  rulesAreAvailable,
  SuperUserRoles,
} from "../helpers/userroles";
import { ENV } from "../app/app.runtime";
import { SelfserviceDialogType } from "../helpers/self-service/models/types";
import { interval, ReplaySubject, Subscription } from "rxjs";
import { OidcSecurityService, PublicEventsService } from "angular-auth-oidc-client";
import { OidcAuthenticationConfig } from "../app/auth/types";
import { NWDUser, OidcTokenResult } from "../helpers/types";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  @Output() basicInfoReady: EventEmitter<any> = new EventEmitter();
  @Output() userLoaded: ReplaySubject<boolean> = new ReplaySubject();
  @Output() healthLoaded: EventEmitter<any> = new EventEmitter();
  @Output() statsLoaded: EventEmitter<any> = new EventEmitter();
  @Output() reloadEvent: EventEmitter<any> = new EventEmitter();
  @Output() errorEvent: EventEmitter<any> = new EventEmitter();
  @Output() roleEvent: EventEmitter<any> = new EventEmitter();

  public state: {
    loading: boolean;
    currentUser: any;
    configuration: any;
    organisations: any[];
    locationCodes: any[];
    products: any[];
    viewingCustomer: any;
    subscriptions: { Internet: []; LightPath: []; L2VPN: []; Port: [] };
    healthIndicators: any[];
    notifications: any[];
    error: boolean;
    errorDialogOpen: boolean;
    errorQueue: any[];
    redirectState: string;
    returnToPage: string;
    strongPreAuthAction: string;
  };
  public settings: any;
  public selfServiceTTokenValidForUpdater: Subscription | undefined = undefined;
  public selfServiceTokenExpiryTime = signal(0);
  public selfServiceTokenValidFor = signal(0);
  public selfServiceStarted = computed(() => this.selfServiceTokenValidFor() > 0);
  private devMode = true;
  private readonly oidc = inject(OidcSecurityService);
  private readonly eventService = inject(PublicEventsService);

  constructor(
    private api: ApiHelper,
    // private matomo: MatomoTracker,
  ) {
    this.state = {
      loading: true,
      currentUser: {},
      configuration: {},
      organisations: [],
      locationCodes: [],
      products: [],
      viewingCustomer: {},
      subscriptions: { Internet: [], LightPath: [], L2VPN: [], Port: [] },
      healthIndicators: [],
      notifications: [],
      error: false,
      errorDialogOpen: false,
      errorQueue: [],
      redirectState: "/#",
      returnToPage: "",
      strongPreAuthAction: "",
    };

    this.settings = {};

    api.loadingEvent.subscribe((event) => {
      if (event.id === "finish_aggregated_stats") {
        this.statsLoaded.emit(event.subscriptionId);
      }
    });

    api.errorEvent.subscribe((event) => {
      this.state.errorQueue.push(event);
      this.state.error = true;
    });

    const env = isDevMode() ? "DEV" : "PROD";
    // this requires a custom dimensions plugin for Matomo (serverside),
    // but seems to be preferred over custom variables.
    // see: https://matomo.org/docs/custom-dimensions
    // this.matomo.setCustomDimension(1, env);

    // scope=visit -> save in cookie for this visitor
    // scope=page -> only save for the current pageview
    // https://matomo.org/docs/custom-variables/
    // if (window["_paq"]) {
    //   this.matomo.setCustomVariable(1, "Environment", env, "visit");
    // }
  }

  get isSelfServiceEnabled(): boolean {
    return ENV.SELFSERVICE_ENABLED;
  }

  get viewingCustomerId(): string {
    return this.state.viewingCustomer.customerId;
  }

  /**
   * Manipulate the current state
   *
   * @param obj any
   */
  setState(obj: any) {
    for (const key in obj) {
      if (this.state.hasOwnProperty(key)) {
        this.state[key] = obj[key];
      }
    }
  }

  updateSelfserviceTokenStatus() {
    this.oidc.getPayloadFromIdToken(false, OidcAuthenticationConfig.SelfService).subscribe(
      (result: OidcTokenResult) => {
        this.selfServiceTokenExpiryTime.set(result.exp * 1000);
        this.selfServiceTTokenValidForUpdater = interval(1000).subscribe(() => {
          this.selfServiceTokenValidFor.set(this.selfServiceTokenExpiryTime() - new Date().getTime());
        });
      },
      (err) => {
        // no token info, so no selfservice.
        // this is here to avoid errors in the browser console.
        this.selfServiceTokenExpiryTime.set(0);
        this.selfServiceTokenValidFor.set(0);
        this.selfServiceTTokenValidForUpdater?.unsubscribe();
        this.selfServiceTTokenValidForUpdater = undefined;
      },
    );
  }

  initStepup() {
    // using the pathname we can redirect with the Angular router.
    const state = { redirect: window.location.pathname };
    this.oidc.setState(JSON.stringify(state), OidcAuthenticationConfig.SelfService).subscribe(() => {
      this.oidc.authorize(OidcAuthenticationConfig.SelfService);
    });
  }

  logout() {
    this.oidc.logoffLocal(OidcAuthenticationConfig.SelfService);
    this.oidc.logoffAndRevokeTokens(OidcAuthenticationConfig.Default).subscribe((result) => {
      console.log("logoff", result);
    });
  }

  /**
   * When in devmode (and unauthenticated), return a usable fake user.
   * Otherwise, return the user object received.
   *
   * @param user the user object as returned from the NWD API
   * @returns an NWDUser object
   */
  ensureValidNWDUser(user: NWDUser): NWDUser {
    if (this.devMode && user.displayName === "Anonymous") {
      return {
        authenticatingAuthority: "",
        authorities: [],
        displayName: "Gert van der Weyde",
        eduPersonPrincipalName: "gert@gravity.nl",
        email: "gert@gravity.nl",
        customerId: "ccadb9d1-0911-e511-80d0-005056956c1a",
        organizationName: "Gravity",
        teams: ["noc_superuserro_team_for_netwerkdashboard"],
        roles: ["SuperuserRO", "Infraverantwoordelijke", "Beveiligingsverantwoordelijke", "SURFwireless-beheerder"],
      };
    }

    return user;
  }

  async fetchConfiguration(): Promise<void> {
    const config$ = this.api.config$();
    config$.subscribe(
      (conf) => {
        this.setState({ configuration: conf });
      },
      (err) => {
        this.handleBackendDown(err);
      },
    );
  }

  /**
   * Retrieve basic user info.
   * If the stored access_token is invalid, it is removed from
   * the localStorage, and load() is called again, redirecting
   * the user to the oauth login URL.
   */
  async fetchUser() {
    if (window.location.href.indexOf("error") > -1) {
      this.setState({ loading: false });
      return;
    }

    // get the configuration
    // among other things, the maintenance banner depends on this.
    this.fetchConfiguration();

    // get the authenticated user info
    // based on this, we'll load customers the user has access to.
    const me$ = this.api.me$();

    me$.subscribe(
      (user) => {
        const actingUser = this.ensureValidNWDUser(user);
        if (actingUser.displayName) {
          this.setState({
            currentUser: actingUser,
          });

          // set the viewing customer if it's not set yet
          // to prevent redirect loops when loading the roles dialog.
          this.setViewingCustomerIfEmpty(actingUser.customerId);
          const hasRoles = (actingUser.roles ?? []).length > 0;
          const hasTeams = (actingUser.teams ?? []).length > 0;

          // no roles means probably a misconfiguration (or no access)
          if (!this.isSuperUserRO() && !hasRoles) {
            this.roleEvent.emit({
              event: "no-roles",
              need: [],
            });
          }

          // let others know that the most basic info has been loaded
          this.basicInfoReady.emit("ready");

          if (!(hasRoles || hasTeams)) {
            return;
          }

          this.api.customers().then((customers) => {
            this.setState({
              loading: false,
              organisations: customers,
            });

            const viewingCustomerGUID = localStorage.getItem("viewingCustomerGUID");
            this.setViewingCustomer(viewingCustomerGUID || actingUser.customerId);

            this.userLoaded.next(true);
          });
        }
      },
      (err) => {
        if (err.status === 401) {
          // @todo what do we do here? anything auth-related is handled by the oidc client.
        } else if (err.status === 403) {
          this.state.currentUser.organizationName = "unknown";
          this.state.currentUser.roles = [];
          this.roleEvent.emit({ event: "no-organization" });
        } else {
        }
      },
    );
  }

  __waaait() {
    return new Promise((resolve) => {
      this.basicInfoReady.subscribe(() => {
        resolve(null);
      });
    });
  }

  setViewingCustomerIfEmpty(guid: string) {
    if (!localStorage.getItem("viewingCustomerGUID")) {
      localStorage.setItem("viewingCustomerGUID", guid);
    }
  }

  switchViewingCustomer(guid: string, redirectLocation: string) {
    this.setViewingCustomer(guid);

    if (redirectLocation !== "") {
      window.location.pathname = redirectLocation;
    } else {
      window.location.reload();
    }
  }

  setViewingCustomer(guid: string) {
    const oldValue = localStorage.getItem("viewingCustomerGUID");
    const newOrganisation = this.state.organisations?.find((o) => o.customerId === guid);

    if (newOrganisation) {
      this.setState({
        viewingCustomer: newOrganisation,
      });
      // also save in localstorage, so models
      // that do not have access to the auth service
      // can find it.
      localStorage.setItem("viewingCustomerGUID", newOrganisation.customerId);
    }
  }

  customerNameForId(guid: string) {
    const customer = this.state.organisations?.filter((e) => e.customerId === guid);
    if (customer.length > 0) {
      return customer[0].name;
    }
    return "";
  }

  isCurrentOrganisation(guid: string) {
    return guid === localStorage.getItem("viewingCustomerGUID");
  }

  hasPendingStrongAction(type: string) {
    return localStorage.getItem("strong_preauth_action") === type;
  }
  getPendingStrongAction(): SelfserviceDialogType {
    return localStorage.getItem("strong_preauth_action") as SelfserviceDialogType;
  }

  clearPendingStrongAction(type: string) {
    if (this.hasPendingStrongAction(type)) {
      localStorage.removeItem("strong_preauth_action");
      localStorage.removeItem("strong_pending");
    }
  }

  getSelfserviceState() {
    let selfserviceState = localStorage.getItem("selfservice_state");
    return JSON.parse(selfserviceState);
  }

  requestSelfserviceDialog(type: string) {
    localStorage.setItem("request_selfservice_dialog", type);
  }

  hasRequestedSelfServiceDialog(): string {
    const dialogType = localStorage.getItem("request_selfservice_dialog");
    localStorage.removeItem("request_selfservice_dialog");
    return dialogType;
  }

  canRequestSelfServiceDialog(): boolean {
    // disabled in config from NWD API
    if (!this.state.configuration.selfServiceEnabled) {
      return false;
    }

    // disabled in runtime config for NWD
    if (!ENV.SELFSERVICE_ENABLED) {
      return false;
    }

    const roles = rolesWithEditAccess();
    return this.hasRole(roles) && !this.isSuperUserRO();
  }

  handleBackendDown = (err) => {
    window.location.pathname = "/error";
  };

  dismissErrors() {
    this.state.errorQueue = [];
    this.state.error = false;
  }

  isSuperUserRO() {
    return this.hasRole(SuperUserRoles) || this.isMemberOf(SuperUserRoles);
  }

  /**
   * Test if the current authenticated user has any (or all) of the given roles.
   *
   * @param roles List of roles to check
   * @param all If true, all roles must be present
   * @returns boolean
   */
  hasRole(roles: string[], all = false): boolean {
    if (all) {
      return roles.every(
        (role) => this.state.currentUser.roles?.includes(role) || this.state.currentUser.teams?.includes(role),
      );
    }

    return roles.some(
      (role) => this.state.currentUser.roles?.includes(role) || this.state.currentUser.teams?.includes(role),
    );
  }

  isMemberOf(team: string[]): boolean {
    return team.some((role) => this.state.currentUser.teams?.includes(role));
  }

  getRoleNames() {
    return this.state.currentUser.roles?.join(", ");
  }

  checkRoleAccess(productType: string, action: "view" | "edit"): RoleCheckResult {
    const result: RoleCheckResult = {
      ok: false,
      event: "requirements-not-met",
      need: [],
      productType,
      requestedAction: action,
    };

    if (!rulesAreAvailable(productType)) {
      // case: this product type is not specified in the roles list
      return result;
    }
    if (AccessRules[productType][action]) {
      // case: rules are defined for this action
      result.need = AccessRules[productType][action];
      result.ok = this.hasRole(result.need);
    } else {
      // case: no rules defined for this action
      result.ok = true;
    }

    if (result.ok) {
      result.event = "requirements-met";
    }

    return result;
  }
}
