import { PipeTransform, Pipe } from "@angular/core";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
dayjs.extend(duration);

@Pipe({
  name: "formatduration",
})
export class FormatDurationPipe implements PipeTransform {
  transform(value: number): string {
    const duration = dayjs.duration(value);
    const days = duration.format("D");
    const hours = duration.format("H");
    const minutes = duration.format("m");

    if (days === "0" && hours === "0") {
      return `${minutes}m`;
    } else if (days === "0") {
      return `${hours}h ${minutes}m`;
    } else {
      return `${days}d ${hours}h ${minutes}m`;
    }
  }
}
