<div class="customer-display-container">
  @if (allowSwitch()) {
    <span class="switch-button">
      <svg-icon (click)="openSwitcher()" src="assets/images/icons/refresh.svg"></svg-icon>
    </span>
  }
  <span class="content">
    <span class="customer-name">{{ customerName() }}</span>
    <span class="customer-abbreviation">{{ customerAbbr() }}</span>
  </span>
</div>
