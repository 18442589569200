import { Component, output, signal, WritableSignal } from "@angular/core";
import { AuthService } from "../../../services/AuthService";
import { CommonModule } from "@angular/common";

import { AngularSvgIconModule } from "angular-svg-icon";

@Component({
  selector: "customer-display",
  templateUrl: "./customer-display.html",
  styleUrls: ["./customer-display.scss"],
  imports: [CommonModule, AngularSvgIconModule],
})
export class CustomerDisplay {
  openSwitchDialog = output<void>();
  public customerName = signal("");
  public customerAbbr = signal("");
  public allowSwitch = signal(false);

  constructor(private auth: AuthService) {
    auth.userLoaded.subscribe((value) => {
      const { viewingCustomer, organisations } = this.auth.state;
      this.customerName.set(viewingCustomer.name);
      this.customerAbbr.set(viewingCustomer.abbreviation);

      this.allowSwitch.set(this.auth.isSuperUserRO() || organisations.length > 1);
    });
  }

  openSwitcher() {
    this.openSwitchDialog.emit();
  }
}
