<div class="dialog dialog-roles">
  <div class="dialog__header">
    <h3 class="dialog__title" [innerHTML]="data.title"></h3>
    <svg-icon src="assets/images/icons/x.svg" class="dialog__close" (click)="closeDialog()"></svg-icon>
  </div>
  <div class="dialog__body dialog__body--grow dialog__body--scroll">
    @if (noContactsFound()) {
      <section>
        <p
          [translateParams]="{ organisation: auth.state.currentUser.organizationName }"
          [translate]="'Role.NoContactsFound'"
        ></p>
      </section>
    } @else if (isFirstStep()) {
      <section>
        <p [innerHTML]="data.description"></p>
        <ul class="dialog-roles__role-list">
          @for (role of filteredRoles; track role.key) {
            <li>
              <div class="dialog-roles__role-title">
                <span>{{ role.title }}</span>
                @if (hasRole(role.key)) {
                  <span class="dialog-roles__current-role blue">
                    <svg-icon src="assets/images/icons/tick.svg"></svg-icon>
                    <span>{{ "Role.CurrentRole" | translate }}</span>
                  </span>
                } @else {
                  <span class="dialog-roles__current-role grey">
                    <span>{{ "Role.Required" | translate }}</span>
                  </span>
                }
              </div>
              <div class="dialog-roles__role-description">{{ role.description }}</div>
            </li>
          }
        </ul>
      </section>
    } @else if (isSecondStep()) {
      <section>
        <p
          [translateParams]="{ organisation: auth.state.currentUser.organizationName }"
          [translate]="'Role.Step2.Title'"
        ></p>

        <ul class="dialog-roles__role-list">
          @for (role of filteredRoles; track role.key) {
            <li>
              <div class="dialog-roles__role-title">
                <span>{{ role.title }}</span>
                <selfservice-toggle
                  [active]="isRequested(role.key)"
                  (click)="toggleRole(role.key)"
                ></selfservice-toggle>
              </div>
              <div class="dialog-roles__role-description">{{ role.description }}</div>
            </li>
          }
        </ul>
      </section>
    }
  </div>
  <div class="dialog__footer">
    <div></div>
    <div class="button__flex">
      <button class="button button--secondary" mat-dialog-close>{{ "Global.Action.Cancel" | translate }}</button>
      @if (isFirstStep()) {
        <button class="button button--primary" (click)="gotoSecondStep()">
          {{ "Global.Action.RequestAuthorization" | translate }}
        </button>
      } @else if (isSecondStep()) {
        <a class="button button--primary" mat-dialog-close [href]="generatedMail">
          {{ "Global.Action.Request" | translate }}
        </a>
      }
    </div>
  </div>
</div>
