import { CommonModule } from "@angular/common";
import { Component, input } from "@angular/core";
import { RouterLink } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "footer",
  templateUrl: "footer.html",
  imports: [CommonModule, RouterLink, TranslateModule],
})
export class FooterComponent {
  protected activeItem = input<string>();
  protected year = new Date().getFullYear();
}
