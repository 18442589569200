import { CommonModule } from "@angular/common";
import { Component, input } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "loader",
  templateUrl: "loader.html",
  styleUrls: ["loader.scss"],
  imports: [CommonModule, TranslateModule],
})
export class LoaderComponent {
  small = input(false);
  detailed = input(false);
  text = input("");
  paddingSize = input("medium");
  iconOnly = input(false);
}
