<div class="footer">
  <div class="footer__copyright">{{ "Footer.Copyright" | translate }} {{ year }}</div>
  <div class="footer__navigation">
    <a
      [routerLink]="['/over-surf']"
      class="footer__link"
      [ngClass]="{ 'footer__link--active': activeItem() === 'about' }"
      >{{ "Footer.AboutSurf" | translate }}</a
    >
    <a
      [routerLink]="['/disclaimer']"
      class="footer__link"
      [ngClass]="{ 'footer__link--active': activeItem() === 'disclaimer' }"
      >{{ "Footer.Disclaimer" | translate }}</a
    >
    <a
      href="https://www.surf.nl/cookieverklaring"
      class="footer__link"
      [ngClass]="{ 'footer__link--active': activeItem() === 'cookie' }"
      target="_blank"
      >{{ "Footer.Cookies" | translate }}</a
    >
    <a
      href="https://www.surf.nl/privacyverklaring-surf"
      class="footer__link"
      [ngClass]="{ 'footer__link--active': activeItem() === 'privacy' }"
      target="_blank"
      >{{ "Footer.Privacy" | translate }}</a
    >
  </div>
</div>
