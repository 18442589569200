import { Component, computed, Inject, input } from "@angular/core";
import { CompleterData } from "../../common/ng2-completer/ng2-completer";
import { MessageService } from "../../../services/messageservice";
import { TranslateModule } from "@ngx-translate/core";
import { AngularSvgIconModule } from "angular-svg-icon";
import { FormsModule } from "@angular/forms";
import { NgClickOutsideDirective } from "ng-click-outside2";

@Component({
  selector: "completer-location",
  templateUrl: "completer-location.html",
  imports: [AngularSvgIconModule, NgClickOutsideDirective, FormsModule, TranslateModule],
})
export class CompleterLocationComponent {
  protected locations = input.required<string[]>();

  protected location: string;
  protected locationSearch: string;
  protected dataService: CompleterData;
  protected selectedLocations: string[] = [];
  protected searchResults: string[] = [];
  protected searchActive = false;

  constructor(@Inject(MessageService) private msgsrv: MessageService) {}

  protected uniqueLocations = computed(() => this.locations().filter((v, i, s) => s.indexOf(v) === i));

  search() {
    const needle = this.location;
    this.searchResults = this.uniqueLocations().filter((v) => v.indexOf(needle.toLowerCase()) !== -1);
  }

  handleCheck(target: any) {
    if (target.checked) {
      this.addLocation(target.value);
    } else {
      this.removeLocation(target.value);
    }
  }

  isLocationFiltered(loc: string) {
    return this.selectedLocations.indexOf(loc) !== -1;
  }

  addLocation(loc: string) {
    const msg = { type: "location", value: loc };
    this.msgsrv.enableFilter(msg);
    if (this.selectedLocations.indexOf(loc) === -1) {
      this.selectedLocations.push(loc);
    }
  }

  removeLocation(org: string) {
    const msg = { type: "location", value: org };
    this.msgsrv.disableFilter(msg);
    const i = this.selectedLocations.indexOf(org);
    if (i > -1) {
      this.selectedLocations.splice(i, 1);
    }
  }

  onClickedOutside() {
    if (this.searchActive) {
      this.searchActive = false;
    }
  }

  searchIsActive() {
    this.searchActive = true;
    if (!this.location || this.location === "") {
      this.searchResults = this.uniqueLocations();
    }
  }
}
