<div class="dialog dialog-spaced">
  <div class="dialog__header">
    <h3 class="dialog__title">
      <svg-icon src="assets/images/icons/lightpath.svg" class="dialog__icon"></svg-icon>
      <span>{{ "ChooseOrganisation.Dialog.ChooseOrganisation" | translate }}</span>
    </h3>
    <svg-icon src="assets/images/icons/x.svg" class="dialog__close" (click)="closeDialog()"></svg-icon>
  </div>
  <section class="dialog__tabpanel">
    <div class="dialog__body dialog__body--scroll dialog__body--grow">
      <div class="dialog__inner">
        <div class="table__wrapper">
          <section class="section">
            <div class="input__wrapper">
              <input
                type="search"
                [(ngModel)]="searchValue"
                value=""
                class="input input--search"
                name=""
                placeholder="{{ 'Form.Placeholder.SearchTerm' | translate }}"
              />
            </div>
          </section>
          <table class="table">
            <tr>
              <th>{{ "Global.Name" | translate }}</th>
              <th>&nbsp;</th>
            </tr>
            @for (org of filteredOrganisations(); track org.customerId) {
              <tr (click)="switchCustomer(org.customerId)">
                <td style="cursor: pointer">{{ org.name }} - {{ org.abbreviation }}</td>
                <td class="text-right">
                  <span style="cursor: pointer">
                    <svg-icon src="assets/images/icons/arrow-long.svg" class="table__arrow"></svg-icon>
                  </span>
                </td>
              </tr>
            }
          </table>
        </div>
      </div>
    </div>
  </section>
</div>
