<div class="dialog dialog-auth">
  <div class="dialog__header">
    <h3 class="dialog__title">{{ "Dialog.Auth.Title" | translate }}</h3>
    <svg-icon src="assets/images/icons/x.svg" class="dialog__close" (click)="closeDialog()"></svg-icon>
  </div>
  @if (errorMessage) {
    <div class="dialog-auth__error">{{ "Dialog.Auth.Error" | translate }}</div>
  }
  <div class="dialog__body">
    @if (!isAuthenticatedForSelfService()) {
      <div class="dialog-auth__flex">
        <div class="dialog-auth__icon-box">
          <svg-icon src="assets/images/icons/lock.svg"></svg-icon>
        </div>
        <div>
          <h4>{{ "Dialog.Auth.Subtitle" | translate }}</h4>
          <p>{{ "Dialog.Auth.Text" | translate }}</p>

          <div class="button__flex">
            <button class="button" (click)="startAuthentication()">{{ "Dialog.Auth.Start" | translate }}</button>
            <button class="button button--secondary" (click)="closeDialog()">
              {{ "Dialog.Auth.Cancel" | translate }}
            </button>
          </div>
        </div>
      </div>
    } @else {
      <div class="dialog-auth__flex">
        <div class="dialog-auth__icon-box dialog-auth__icon-box--success">
          <svg-icon src="assets/images/icons/tick.svg"></svg-icon>
        </div>
        <div>
          <h4>{{ "Dialog.Auth.SubtitleSuccess" | translate }}</h4>
          <p>{{ "Dialog.Auth.TextSuccess" | translate }}</p>

          <div class="button__flex">
            <button class="button button--secondary" (click)="closeDialog()">
              {{ "Dialog.Auth.Close" | translate }}
            </button>
          </div>
        </div>
      </div>
    }
  </div>
</div>
