<div>
  <ng-template #started>
    <div class="selfservice-status__container">
      <span> Selfservice <span class="selfservice-started">toegestaan</span> </span>
      <span class="selfservice-status__row access">
        <svg-icon src="assets/images/icons/lock.svg" class="selfservice-started__icon"></svg-icon>
        <span>{{ auth.selfServiceTokenValidFor() | formatduration }}</span>
      </span>
    </div>
  </ng-template>
  <ng-template #notStarted>
    <div class="selfservice-status__container">
      <span class="notstarted">
        <strong>{{ "Global.AuthenticationNeeded" | translate }}</strong>
        {{ "Global.AuthenticationNeededText" | translate }}
      </span>
      <span (click)="startSelfService()" class="selfservice-status__row noaccess">
        <svg-icon src="assets/images/icons/lock.svg" class="selfservice-not-started__icon"></svg-icon>
        <span>{{ "ShortcutMenu.SelfService" | translate }}</span>
      </span>
    </div>
  </ng-template>

  <div *ngIf="selfserviceStarted(); then started; else notStarted"></div>
</div>
