import { CommonModule } from "@angular/common";
import { Component, Inject, inject, output } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogClose } from "@angular/material/dialog";
import { TranslateModule } from "@ngx-translate/core";
import { AngularSvgIconModule } from "angular-svg-icon";

@Component({
  selector: "dialog-generic",
  templateUrl: "dialog-generic.html",
  styleUrls: ["dialog-generic.scss"],
  imports: [CommonModule, AngularSvgIconModule, TranslateModule, MatDialogClose],
})
export class DialogGenericComponent {
  private dialogRef = inject(MatDialogRef<DialogGenericComponent>);
  public emitter = output<boolean>();

  protected title: any;
  protected message: any;
  protected state: "success" | "error";
  protected code: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.title = data.title;
    this.message = data.message;
    this.state = data.state;
    this.code = data.code;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
