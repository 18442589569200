import { PipeTransform, Pipe } from "@angular/core";

const STATUS_MAPPING = {
  active: "ok",
  ok: "ok",
  notfound: "unknown",
  unknown: "unknown",
  notraffic: "no-traffic",
  malfunction: "warning",
  initial: "pending",
  pending: "pending",
  "active-corero-filters": "ok",
  "inactive-corero-filters": "no-traffic",
  "open-incidents": "warning",
};

@Pipe({
  name: "status_to_icon",
})
export class StatusToIconPipe implements PipeTransform {
  transform(value: string): string {
    return STATUS_MAPPING[value] || "unknown";
  }
}
