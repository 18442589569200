// Policy service
//
// A policy is defined by:
// - the path or resource to be accessed
// - the current clientID
// - the HTTP method
//
// The result of a call to isAllowed() is a boolean.
// isAllowed() checks the policy for a request and returns true or false
//

import { Injectable } from "@angular/core";
import { AuthService } from "./AuthService";
import { Router } from "@angular/router";

export const SURF_CUSTOMERS = ["c9b5e717-0b11-e511-80d0-005056956c1a", "3902a8cf-776f-e611-80e0-005056956c1a"];
export const SURF_ROLES = ["automation-admins", "automation-developers", "ten", "noc-engineers", "SuperuserRO"];

@Injectable()
export class PolicyService {
  constructor(
    private auth: AuthService,
    private route: Router,
  ) {
    // load the OPA WASM here
  }

  isAllowed(resource: string | undefined, action: "GET" | "POST" | "PUT" | "DELETE") {
    return this.checkLocal(resource);
  }

  checkLocal(resource?: string): boolean {
    const { currentUser } = this.auth.state;
    // no resource given? then use the current route.
    const requestedResource = resource || this.route.routerState.snapshot.url;

    const customerId = localStorage.getItem("viewingCustomerGUID");
    const isDomainsAdmin = this.auth.hasRole(["surfdomeinen_admins"]);
    const specialAccess = this.auth.hasRole(SURF_ROLES) && SURF_CUSTOMERS.includes(customerId);
    const isOwnCustomer = customerId === currentUser.customerId;

    if (requestedResource.startsWith("/domains")) {
      return isDomainsAdmin || specialAccess || isOwnCustomer;
    }

    return false;
  }
}
