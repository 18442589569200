import { Component, inject, Inject, signal } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AuthService } from "../../../services/AuthService";
import { CommonModule } from "@angular/common";
import { AngularSvgIconModule } from "angular-svg-icon";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "dialog-auth",
  templateUrl: "dialog-auth.html",
  styleUrls: ["dialog-auth.scss"],
  imports: [CommonModule, AngularSvgIconModule, TranslateModule],
})
export class DialogAuthComponent {
  private readonly auth = inject(AuthService);
  private readonly dialogRef = inject(MatDialogRef<DialogAuthComponent>);
  protected isAuthenticatedForSelfService = signal(false);
  protected errorMessage = false;
  private selfserviceState = "";

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      selfserviceState: string;
      state: boolean;
      initialAction?: string;
    },
  ) {
    if (data.state) {
      this.isAuthenticatedForSelfService.set(this.auth.selfServiceStarted());
      this.errorMessage = !this.isAuthenticatedForSelfService;
    }
    this.selfserviceState = data.selfserviceState || "";
    localStorage.setItem("selfservice_state", JSON.stringify(this.selfserviceState));

    this.isAuthenticatedForSelfService.set(this.auth.selfServiceStarted());
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  startAuthentication() {
    this.auth.initStepup();
  }
}
